import React, { CSSProperties, HTMLAttributes } from 'react'
import { cn } from '@hmm/utils'
import styles from './Icon.module.css'

export interface IIconProps extends HTMLAttributes<HTMLSpanElement> {
  /**
   * Направление для иконки-стрелки.
   */
  direction?: 'left' | 'top' | 'right' | 'bottom'

  /**
   * Размер иконки.
   */
  size?: 'ns' | 'xs' | 's' | 'm' | 'n' | 'l'

  /**
   * Стили иконки.
   *
   * @default {}
   */
  style?: CSSProperties

  /**
   * Адрес иконки.
   */
  url?: string,
  children?: React.ReactNode
}

export const cnIcon = cn('Icon', styles)

export const Icon = ({
  direction,
  size,
  url,
  style = {},
  children,
  ...props
}: IIconProps) => {
  const className = cnIcon({ direction, size }, [props.className])

  if (url !== undefined) {
    style.backgroundImage = `url('${url}')`
  }

  return (
    <span {...props} aria-hidden className={className} style={style}>
      {children}
    </span>
  )
}

Icon.displayName = cnIcon()
