import React, { useCallback, useMemo } from 'react'
import { compose, composeU, IClassNameProps } from '@hmm/utils'
import { classnames } from '@bem-react/classnames'
import {
  ISocialVendor,
  sortSocials,
  getMainSocials,
  getSecondarySocials,
  SOCIALS_ORDER_MAP,
  SOCIALS_SECONDARY_TRESHOLD
} from '../../../models/socials'
import { Button } from '@hmm/components/Button'
import {
  Icon as IconBase,
  withHmmProfile,
  withSocialVk,
  withSocialOk,
  withSocialYa,
  withSocialMailru
} from '@hmm/components/Icon'
import styles from './LoginVariants.module.css'

const Icon = compose(withHmmProfile)(IconBase)
const SocialIcon = composeU(
  withSocialYa,
  withSocialMailru,
  withSocialOk,
  withSocialVk
)(IconBase)

const SOCIAL_TO_ICON_MAP: Record<string, string> = {
  mail: 'mailru',
  odnoklassniki: 'ok',
  yandex: 'ya'
}

export interface ILoginVariantsProps extends IClassNameProps {
  fields: React.ReactNode
  socials: ISocialVendor[]
  /** выбранный вариант */
  variant?: string
  onVariantChange: (variant: string) => void
}

export function LoginVariants({
  fields,
  socials,
  variant,
  onVariantChange,
  className
}: ILoginVariantsProps) {
  const sorted = useMemo(
    () => sortSocials(socials, SOCIALS_ORDER_MAP),
    [socials]
  )
  const mainSocials = useMemo(
    () => getMainSocials(sorted, SOCIALS_SECONDARY_TRESHOLD),
    [sorted]
  )
  const secondarySocials = useMemo(
    () => getSecondarySocials(sorted, SOCIALS_SECONDARY_TRESHOLD),
    [sorted]
  )
  const handleVariantClick = useCallback(
    (e: React.BaseSyntheticEvent) => {
      const clickedVariant = e.currentTarget.getAttribute('name')
      clickedVariant && onVariantChange(clickedVariant)
    },
    [onVariantChange]
  )

  const variantClasses = useCallback(
    (variantName: string, currentVariant?: string) => {
      if (!currentVariant) return
      return currentVariant === variantName
        ? styles.VariantCurrent
        : styles.VariantHidden
    },
    []
  )

  return (
    <ul className={classnames(styles.LoginVariants, className)}>
      <li
        key="password"
        className={classnames(
          styles.Variant,
          styles.VariantMain,
          variantClasses('password', variant)
        )}
      >
        <Button
          onClick={handleVariantClick}
          name="password"
          size="m"
          variant="outlined"
          fullWidth
          className={styles.VariantButton}
          startIcon={<Icon hmm="profile" className={styles.VariantIcon} />}
        >
          Электронная почта
        </Button>
        <div className={styles.VariantForm}>{fields}</div>
      </li>
      {mainSocials.map((vendor) => (
        <li
          key={vendor.name}
          className={classnames(
            styles.Variant,
            styles.VariantMain,
            variantClasses(vendor.name, variant)
          )}
        >
          <Button
            size="m"
            variant="outlined"
            fullWidth
            className={styles.VariantButton}
            href={vendor.url}
            startIcon={
              <SocialIcon
                // @ts-ignore
                social={SOCIAL_TO_ICON_MAP[vendor.name] || vendor.name}
                className={styles.VariantIcon}
              />
            }
          >
            {vendor.title}
          </Button>
        </li>
      ))}
      {secondarySocials.map((vendor) => (
        <li
          key={vendor.name}
          className={classnames(
            styles.Variant,
            variantClasses(vendor.name, variant)
          )}
        >
          <Button
            size="m"
            variant="outlined"
            fullWidth
            className={styles.VariantButton}
            href={vendor.url}
            startIcon={
              <SocialIcon
                // @ts-ignore
                social={SOCIAL_TO_ICON_MAP[vendor.name] || vendor.name}
                className={styles.VariantIcon}
              />
            }
          >
            {vendor.title}
          </Button>
        </li>
      ))}
    </ul>
  )
}
