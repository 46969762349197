import React from 'react'
import { cnIcon } from '../Icon'
import { withBemMod } from '@hmm/utils'

export interface IconSocialOkProps {
  social?: 'ok'
}

export const withSocialOk = withBemMod<IconSocialOkProps>(
  cnIcon,
  {
    social: 'ok'
  },
  Icon => props => (
    <Icon {...props}>
      <svg
        className="svg"
        role="img"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#F2720C"
            d="M18 0c9.975 0 18 8.025 18 18s-8.025 18-18 18S0 27.975 0 18 8.025 0 18 0z"
          />
          <path
            fill="#FFF"
            d="M23.25 18C22.5 18 21 19.5 18 19.5S13.5 18 12.75 18c-.825 0-1.5.675-1.5 1.5 0 .75.45 1.125.75 1.275.9.525 3.75 1.725 3.75 1.725l-3.225 4.05s-.6.675-.6 1.2c0 .825.675 1.5 1.5 1.5.75 0 1.125-.525 1.125-.525L18 24.75l3.375 3.975s.375.525 1.125.525c.825 0 1.5-.675 1.5-1.5 0-.45-.6-1.2-.6-1.2l-3.15-4.05s2.85-1.2 3.75-1.725c.3-.225.75-.525.75-1.275 0-.825-.675-1.5-1.5-1.5zM18 6.75c-2.925 0-5.25 2.325-5.25 5.25s2.325 5.25 5.25 5.25 5.25-2.325 5.25-5.25S20.925 6.75 18 6.75zm0 7.875c-1.425 0-2.625-1.2-2.625-2.625s1.2-2.625 2.625-2.625 2.625 1.2 2.625 2.625-1.2 2.625-2.625 2.625z"
          />
        </g>
      </svg>
    </Icon>
  )
)
