import React from 'react'
import { cnIcon } from '../Icon'
import { withBemMod } from '@hmm/utils'

export interface IconSocialMailruProps {
  social?: 'mailru'
}

export const withSocialMailru = withBemMod<IconSocialMailruProps>(
  cnIcon,
  {
    social: 'mailru'
  },
  Icon => props => (
    <Icon {...props}>
      <svg
        className="svg"
        role="img"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
      >
        <g fill="none" fillRule="evenodd">
          <circle cx="18" cy="18" r="18" fill="#3688C8" />
          <path
            fill="#FAA832"
            d="M18.235 12.43c1.507 0 2.923.666 3.963 1.71v.003c0-.5.337-.878.805-.878h.118c.738 0 .888.692.888.91l.004 7.79c-.052.51.525.773.846.447 1.25-1.286 2.745-6.608-.777-9.692-3.282-2.875-7.687-2.4-10.03-.786-2.49 1.72-4.082 5.522-2.535 9.094 1.688 3.897 6.516 5.06 9.386 3.9 1.45-.587 2.123 1.38.614 2.02-2.28.974-8.625.876-11.59-4.263-2.003-3.47-1.896-9.576 3.416-12.74 4.06-2.42 9.42-1.75 12.65 1.627 3.376 3.53 3.18 10.14-.115 12.71-1.492 1.167-3.71.03-3.695-1.67l-.015-.557c-1.04 1.032-2.422 1.634-3.93 1.634-2.976 0-5.595-2.627-5.595-5.6 0-3.01 2.62-5.66 5.597-5.66zM22 17.863c-.112-2.19-1.734-3.506-3.692-3.506h-.074c-2.26 0-3.514 1.78-3.514 3.804 0 2.27 1.517 3.7 3.505 3.7 2.217 0 3.675-1.625 3.78-3.55L22 17.864z"
          />
        </g>
      </svg>
    </Icon>
  )
)
