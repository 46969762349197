import React from 'react'
import { cnIcon } from '../Icon'
import { withBemMod } from '@hmm/utils'

export interface IconSocialYaProps {
  social?: 'ya'
}

export const withSocialYa = withBemMod<IconSocialYaProps>(
  cnIcon,
  {
    social: 'ya'
  },
  Icon => props => (
    <Icon {...props}>
      <svg
        className="svg"
        role="img"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
      >
        <g fill="none">
          <circle cx="18" cy="18" r="18" fill="#E7322B"></circle>
          <path
            fill="#FFF"
            d="M21.693 7h-3.175c-3.12 0-5.766 2.493-5.766 7.334 0 2.903 1.282 5.044 3.565 6.1l-4.26 8.097c-.14.268 0 .47.222.47h1.973c.167 0 .28-.055.335-.202l3.872-7.95h1.392v7.95c0 .087.083.205.195.205h1.726c.167 0 .223-.087.223-.233V7.292C22 7.088 21.886 7 21.69 7zM20 19h-1.22C16.86 19 15 17.605 15 14.117 15 10.482 16.744 9 18.517 9H20v10z"
          ></path>
        </g>
      </svg>
    </Icon>
  )
)
