import { createAtom } from '@reatom/core'

export interface ISocialVendor {
  name: string
  title?: string
  url?: string
}

export const SOCIALS_ORDER_MAP: Record<string, number> = {
  vk: 100,
  ok: 90,
  yandex: 80,
  mail: 70
}

export const SOCIALS_SECONDARY_TRESHOLD = 2
export const WHITELISTED_SOCIALS = ['vk', 'ok', 'mail', 'yandex']

export function filterAllowedSocials(
  socials: ISocialVendor[],
  whitelist: string[]
): ISocialVendor[] {
  return socials.filter((vendor) => whitelist.includes(vendor.name))
}

/**
 * Сортирует соцсети в порядке переданном в map
 * @param socials
 * @param weightMap map имени соцсети на ее порядок
 */
export function sortSocials(
  socials: ISocialVendor[],
  weightMap: Record<string, number>
): ISocialVendor[] {
  return socials.sort((a, b) => {
    return (weightMap[b.name] || 0) - (weightMap[a.name] || 0)
  })
}

/**
 * Возвращает основные сети из сортированного списка
 * @param socials
 * @param count
 */
export function getMainSocials(
  socials: ISocialVendor[],
  count?: number
): ISocialVendor[] {
  return socials.slice(0, count)
}

/**
 * Возвращает вторичные сети из сортированого списка
 * @param socials
 * @param main_count количество основных сетей
 */
export function getSecondarySocials(
  socials: ISocialVendor[],
  main_count?: number
): ISocialVendor[] {
  return socials.slice(main_count || socials.length)
}

export const SocialsAtom = createAtom(
  {
    set: (vendors: ISocialVendor[]) =>
      sortSocials(
        filterAllowedSocials(vendors, WHITELISTED_SOCIALS),
        SOCIALS_ORDER_MAP
      )
  },
  (track, state: ISocialVendor[] = []) => {
    track.onAction('set', (vendors) => state = vendors )
    return state;
  }
)

export const MainSocialsAtom = createAtom({ socials: SocialsAtom }, (track) =>
  getMainSocials(track.get('socials'), SOCIALS_SECONDARY_TRESHOLD)
)
export const SecondarySocialsAtom = createAtom(
  { socials: SocialsAtom },
  (track) =>
    getSecondarySocials(track.get('socials'), SOCIALS_SECONDARY_TRESHOLD)
)
