import React from 'react'
import { cnIcon, IIconProps } from "../Icon";
import { withBemMod } from '@hmm/utils'

export const withHmmProfile = withBemMod<{ hmm?: 'profile'}, IIconProps>(
  cnIcon,
  {
    hmm: 'profile'
  },
  Icon => ({ hmm, ...props }) => (
    <Icon {...props}>
      <svg width="26" height="23" viewBox="0 0 26 23" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 22.0501V22.0501C6.96507 14.3293 18.1355 14.0035 23.5421 21.4218L24 22.0501" stroke="currentColor" strokeWidth="3" fill="none"/>
        <ellipse cx="12.577" cy="7.27996" rx="6.34615" ry="6.33007" fill="currentColor"/>
      </svg>
    </Icon>
  )
)
