import React from 'react'
import { cnIcon } from '../Icon'
import { withBemMod } from '@hmm/utils'

export interface IconSocialVkProps {
  social?: 'vk'
}

export const withSocialVk = withBemMod<IconSocialVkProps>(
  cnIcon,
  {
    social: 'vk'
  },
  Icon => props => (
    <Icon {...props}>
      <svg
        className="svg"
        role="img"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="36"
        height="36"
        viewBox="0 0 36 36"
      >
        <g fill="none" fillRule="evenodd">
          <path
            fill="#54769B"
            d="M18 0c9.975 0 18 8.025 18 18s-8.025 18-18 18S0 27.975 0 18 8.025 0 18 0z"
          />
          <path
            fill="#FFF"
            d="M18.75 24.75c.75 0 .75-1.05.75-1.5 0-.75.75-1.5 1.5-1.5s2.025 1.275 3 2.25c.75.75.75.75 1.5.75h2.25s1.5-.075 1.5-1.5c0-.45-.525-1.275-2.25-3-1.5-1.5-2.25-.75 0-3.75 1.35-1.875 2.4-3.525 2.25-3.975-.15-.45-3.975-1.2-4.5-.525-1.5 2.25-1.8 2.775-2.25 3.75-.75 1.5-.825 2.25-1.5 2.25s-.75-1.425-.75-2.25c0-2.475.375-4.2-.75-4.5h-2.25c-1.2 0-2.25.75-2.25.75s-.9.75-.75.75c.225 0 1.5-.3 1.5.75V15s0 3-.75 3-2.25-3-3.75-5.25c-.6-.9-.75-.75-1.5-.75H7.5c-.75 0-.825.45-.75.75 1.5 3.75 2.55 6.075 5.4 9.075 2.625 2.7 4.35 2.85 5.85 2.925h.75z"
          />
        </g>
      </svg>
    </Icon>
  )
)
